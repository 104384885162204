.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content-page {
  padding-top: 10%; /* default paddingTop for small screens */
}

@media (min-width: 768px) {
  /* for medium screens */
  .content-page {
    padding-top: 8%;
  }
}

@media (min-width: 992px) {
  /* for large screens */
  .content-page {
    padding-top: 6%;
  }
}

@media (min-width: 1200px) {
  /* for extra large screens */
  .content-page {
    padding-top: 6%;
  }
}

@media (min-width: 1600px) {
  /* for extra large screens */
  .content-page {
    padding-top: 3%;
  }
}

.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.footer {
  margin-top: 20px; /* adjust as needed */
  padding-top: 20px !important; /* adjust as needed */
}

/* Login and Sign up Form */
.center-login {
  margin-right: 5% !important;
  margin-left: auto !important;
}

.carousel-caption-background {
  background-color: rgba(240, 244, 248, 0.6);
  padding: 15x; /* Optional: some padding */
  color: rgb(8, 39, 100) !important;

  font-size: 0.9rem;
  font-weight: 400;
}

/* Global styles for pie/doughnut charts */
.compact-chart-container {
  width: 100%;
  max-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.compact-chart {
  width: 100%;
  max-width: 400px;
  max-height: 350px;
  position: relative;
}

/* Dark mode support for charts */
.dark-mode .compact-chart canvas {
  filter: brightness(0.9);
}

/* Responsive adjustments for charts */
@media (max-width: 768px) {
  .compact-chart {
    max-width: 300px;
    max-height: 300px;
  }
}

@media (max-width: 576px) {
  .compact-chart {
    max-width: 250px;
    max-height: 250px;
  }
}

/* Animation container size adjustments */
.compact-animation {
  max-height: 300px;
  overflow: hidden;
}

/* Scrollable lists for goals and other data */
.compact-goal-list {
  max-height: 200px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.compact-goal-list::-webkit-scrollbar {
  width: 6px;
}

.compact-goal-list::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.compact-goal-list::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.dark-mode .compact-goal-list::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
}

.dark-mode .compact-goal-list::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
}
